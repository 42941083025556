import { QueryClientProvider } from "@tanstack/react-query";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import toast, { Toaster } from "react-hot-toast";
import { MainErrorFallback } from "@/components/errors/main";
import { queryClient } from "@/lib/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { RecaptchaProvider } from "@/lib/captcha-provider";
import NoInternetError from "@/lib/no-internet-error";
import { createPortal } from "react-dom";

type AppProviderProps = {
	children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
	const toastOptions = {
		success: {
			style: {
				borderRadius: "10px",
				background: "#333",
				color: "#fff",
			},
		},
		error: {
			style: {
				borderRadius: "10px",
				background: "#333",
				color: "#fff",
			},
		},
	};

	return (
		<React.Suspense
			fallback={
				<div className="flex h-screen w-screen items-center justify-center">
					{/* <Spinner size="xl" /> */}
				</div>
			}
		>
			<ErrorBoundary FallbackComponent={MainErrorFallback}>
				<HelmetProvider>
					<RecaptchaProvider>
						<QueryClientProvider client={queryClient}>
							{import.meta.env.DEV && <ReactQueryDevtools />}
							<NoInternetError>{children}</NoInternetError>
							{typeof document !== "undefined" &&
								createPortal(
									<Toaster
										toastOptions={toastOptions}
										position="top-center"
										containerStyle={{
											zIndex: 100000, // Higher than popup's z-index of 10000
										}}
									/>,
									document.body,
								)}
						</QueryClientProvider>
					</RecaptchaProvider>
				</HelmetProvider>
			</ErrorBoundary>
		</React.Suspense>
	);
};
