import { useEffect, useState, type ReactNode } from "react";
import { WifiOff } from "lucide-react";
import { createPortal } from "react-dom";

interface NoInternetErrorProps {
	children: ReactNode;
}

const NoInternetError = ({ children }: NoInternetErrorProps) => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);

	useEffect(() => {
		// Update network status
		const handleOnline = () => setIsOnline(true);
		const handleOffline = () => setIsOnline(false);

		// Add event listeners
		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);

		// Cleanup event listeners
		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, []);

	return (
		<>
			{children}

			{!isOnline &&
				createPortal(
					<div className="fixed inset-0 bg-gray-900/95 backdrop-blur-sm flex flex-col items-center justify-center z-50">
						<div className="bg-white/10 p-8 rounded-xl shadow-2xl max-w-md mx-4 text-center">
							<WifiOff
								className="w-16 h-16 mb-4 mx-auto text-white/80"
								strokeWidth={1.5}
							/>
							<h1 className="text-white text-2xl font-bold mb-3">
								No Internet Connection
							</h1>
							<p className="text-gray-300">
								Please check your internet connection and try again.
							</p>
						</div>
					</div>,
					document.body,
				)}
		</>
	);
};

export default NoInternetError;
