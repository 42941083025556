import { Navigate, useNavigate } from "react-router-dom";
import { useUser } from "./auth";
import { useEffect } from "react";
import { PageLoad } from "@/components/ui/spinner/page-load";

export const EmailUnverifiedRoute = ({
	children,
}: { children: React.ReactNode }) => {
	const { data: user, isLoading, isError, refetch } = useUser();

	if (isLoading) return <></>;

	if (!user || user.emailStatus === "verified" || !user.emailVerification) {
		return <Navigate to="/" replace />;
	}

	return <>{children}</>;
};
