import * as React from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./app";
import { api } from "./lib/api-client";
// import { enableMocking } from './testing/mocks';
import * as Sentry from "@sentry/react";
import { env } from "./config/env";

if (env.NODE_ENV === "production")
	Sentry.init({
		dsn: "https://bb903c13b2acbf90600e0133761925a8@o4508185884491776.ingest.us.sentry.io/4508189994516480",
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ["localhost", /^https:\/\/app\.fullpilot\.com/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});

const root = document.getElementById("root");
if (!root) throw new Error("No root element found");

// enableMocking().then(() => {
createRoot(root).render(
	// <React.StrictMode>
	<App />,
	// </React.StrictMode>,
);
// });

//Add import so that it sets env
const apiTest = api;
