import { Navigate, useNavigate } from "react-router-dom";
import { useUser } from "./auth";
import { useEffect } from "react";
import { PageLoad } from "@/components/ui/spinner/page-load";

export const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
	const { data: user, isLoading, isError, refetch } = useUser();

	if (isLoading) return <PageLoad />;
	if (!user) return <Navigate to="/auth/login" replace />;
	if (user.emailStatus !== "verified" && location.pathname !== "/logout") {
		return <Navigate to="/confirm/verify-email" replace />;
	}

	return <>{children}</>;
};
