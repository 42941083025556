import { Navigate, useNavigate } from "react-router-dom";
import { useUser } from "./auth";
import { useEffect } from "react";
import { PageLoad } from "@/components/ui/spinner/page-load";

export const LoggedOutRoute = ({ children }: { children: React.ReactNode }) => {
	const { data: user, isLoading, isError, refetch } = useUser();

	if (isLoading) return <PageLoad />;
	if (user) return <Navigate to="/home" replace />;
	return <>{children}</>;
};
