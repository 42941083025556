import axios, {
	AxiosError,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from "axios";
import { client } from "@/api-types/services.gen";
import { env } from "@/config/env";
import toast from "react-hot-toast";
import { getUserCsrf, postUserRefreshToken } from "@/api-types/services.gen";
import * as Sentry from "@sentry/react";

// Configure the client
client.setConfig({
	baseURL: env.API_URL,
	withCredentials: true,
});

// Variables to store tokens
let csrfToken: string | null = null;

// Define authentication routes to exclude from 401 handling
const authRoutes = [
	"/user/login",
	"/user/register",
	"/user/refresh-token",
	"/user/forget-password",
	"/user/new-forget-password",
	"/user/update-password",
];

export const getCookie = (name: string): string | null => {
	const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
	if (match) {
		return match[2];
	}
	return null;
};

/**
 * Fetches the CSRF token from the server and stores it in the csrfToken variable.
 */
export const fetchCsrfToken = async () => {
	try {
		const response = await getUserCsrf();
		csrfToken = response.data?.data?.csrfToken || "";
	} catch (error) {
		Sentry.captureException(error);
		console.error("Failed to fetch CSRF token:", error);
	}
};

// Immediately invoke fetchCsrfToken to retrieve the CSRF token during initialization
fetchCsrfToken();

/**
 * Interceptor to attach the CSRF token to every outgoing request.
 * @param config - The Axios request configuration.
 * @returns The modified Axios request configuration with the CSRF token.
 */
const csrfRequestInterceptor = async (
	config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
	// Extract the CSRF token from the cookies
	const csrfTokenFromCookie = getCookie("XSRF-TOKEN");

	if (config.headers) {
		config.headers.Accept = "application/json";
		config.headers["X-CSRF-Token"] = csrfTokenFromCookie || csrfToken || "";
	}

	config.withCredentials = true;
	return config;
};

// Attach the CSRF request interceptor to the global Axios instance
client.instance.interceptors.request.use(csrfRequestInterceptor);

// Function to introduce a delay
const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

// Interceptor to add delay in development mode
const delayInterceptor = async (
	config: InternalAxiosRequestConfig,
): Promise<InternalAxiosRequestConfig> => {
	if (env.NODE_ENV === "development") {
		await delay(400);
	}
	return config;
};

// Attach the delay interceptor to the global Axios instance
client.instance.interceptors.request.use(delayInterceptor);

// Flag to track if a token refresh is in progress
let isRefreshing = false;
let failedQueue: Array<{
	resolve: (value?: unknown) => void;
	reject: (reason?: any) => void;
	config: any;
}> = [];

const processQueue = (error: any) => {
	for (const prom of failedQueue) {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve();
		}
	}

	failedQueue = [];
};

// Global Axios interceptor
client.instance.interceptors.response.use(
	(response) => response,
	async (error: AxiosError) => {
		const originalRequest = error.config as any;

		// Check if it's a refresh token request and has a 401 status
		if (
			error.response?.status === 401 &&
			originalRequest.url?.includes("/user/refresh-token")
		) {
			// // Refresh token request failed with 401, redirect to login
			// window.location.href = "/auth/login";
			// toast.error("Your session has expired. Please log in again.");
			// return Promise.reject(error);
		}

		// If the request URL is in authRoutes, do not handle 401 globally
		if (
			originalRequest.url &&
			authRoutes.some((route) => originalRequest.url?.includes(route))
		) {
			// Let the error propagate to be handled by the form component
			return Promise.reject(error);
		}

		// Handle other 401 errors (potentially for token refresh)
		if (error.response?.status === 401 && !originalRequest._retry) {
			if (isRefreshing) {
				return new Promise((resolve, reject) => {
					failedQueue.push({ resolve, reject, config: originalRequest });
				})
					.then(() => {
						return client.instance(originalRequest);
					})
					.catch((err) => {
						return Promise.reject(err);
					});
			}

			originalRequest._retry = true;
			isRefreshing = true;

			try {
				const refreshResponse = await postUserRefreshToken();

				if (!refreshResponse.data?.success) {
					// Token refresh failed
					return Promise.reject(error);
				}
				// Token refresh successful
				processQueue(null);
				return client.instance(originalRequest);
			} catch (refreshError) {
				Sentry.captureException(refreshError);
				console.log(refreshError);
				processQueue(refreshError);
				return Promise.reject(refreshError);
			} finally {
				isRefreshing = false;
			}
		}

		return Promise.reject(error);
	},
);

export function handleApiError(res: any) {
	if (res instanceof AxiosError) {
		throw new Error(
			res.response?.data?.message || "An unexpected error occurred",
		);
	}
}

// Export the client for use in other parts of your application
export { client as api };
