import { PageLoad } from '@/components/ui/spinner/page-load';
import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const AppRoot = () => {
  const location = useLocation();

  if (location.pathname === '/') {
    return (<>
      <Navigate to="/home" />
      <PageLoad />
    </>)
  }

  return (
    <Suspense
      fallback={
        <PageLoad />
      }
    >
      <ErrorBoundary
        key={location.pathname}
        fallback={<div>Something went wrong!</div>}
      >
        <Outlet />
      </ErrorBoundary>
    </Suspense>
  );
};