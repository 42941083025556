import { env } from "@/config/env";
import {
	GoogleReCaptchaProvider,
	useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import * as Sentry from "@sentry/react";

export const RecaptchaProvider = ({
	children,
}: { children: React.ReactNode }) => {
	return (
		<GoogleReCaptchaProvider reCaptchaKey={env.RECAPTCHA_SITE_KEY}>
			{children}
		</GoogleReCaptchaProvider>
	);
};

export const useCaptcha = (action: string) => {
	const { executeRecaptcha } = useGoogleReCaptcha();

	const getToken = async (): Promise<string> => {
		if (env.NODE_ENV === "development") {
			return "disabled_for_development";
		}

		if (!executeRecaptcha) {
			throw new Error("reCAPTCHA not initialized");
		}

		try {
			const token = await executeRecaptcha(action);
			return token;
		} catch (error) {
			Sentry.captureException(error);
			console.error("Error generating reCAPTCHA token:", error);
			throw new Error("Failed to generate reCAPTCHA token");
		}
	};

	return { getToken };
};
