import { QueryClient } from "@tanstack/react-query";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { AppRoot } from "./routes/app/root";
import { ProtectedRoute } from "@/lib/protected-route";
import { LoggedOutRoute } from "@/lib/logged-out-route";
import { EmailUnverifiedRoute } from "@/lib/email-unverified-route";
import * as Sentry from "@sentry/react";

const lazyLoadRoute = (importFunc: () => Promise<any>) => ({
	lazy: async () => {
		try {
			const module = await importFunc();
			return { Component: module[Object.keys(module)[0]] };
		} catch (error) {
			Sentry.captureException(error);
			console.error("Failed to load route:", error);
			return { Component: () => <Navigate to="/" replace /> };
		}
	},
});

export const createRouter = (queryClient: QueryClient) =>
	createBrowserRouter([
		{
			path: "/confirm/",
			element: (
				<EmailUnverifiedRoute>
					<AppRoot />
				</EmailUnverifiedRoute>
			),
			children: [
				{
					path: "/confirm/verify-email/:token",
					...lazyLoadRoute(() => import("./routes/auth/verify-email")),
				},
				{
					path: "/confirm/verify-email",
					...lazyLoadRoute(() => import("./routes/auth/login-verify-email")),
				},
				{
					path: "/confirm/verify-new-email/:token",
					...lazyLoadRoute(() => import("./routes/auth/verify-new-email")),
				},
				{
					path: "/confirm/resend-email",
					...lazyLoadRoute(() => import("./routes/auth/resend-verify-email")),
				},
			],
		},
		{
			path: "/auth/",
			element: (
				<LoggedOutRoute>
					<AppRoot />
				</LoggedOutRoute>
			),
			children: [
				{
					path: "/auth/login",
					...lazyLoadRoute(() => import("./routes/auth/login")),
				},
				{
					path: "/auth/register",
					...lazyLoadRoute(() => import("./routes/auth/register")),
				},
				{
					path: "/auth/forget-password",
					...lazyLoadRoute(() => import("./routes/auth/forget-password")),
				},
				{
					path: "/auth/reset-password/:token",
					...lazyLoadRoute(() => import("./routes/auth/reset-password")),
				},
			],
		},
		{
			path: "/",
			element: (
				<ProtectedRoute>
					<AppRoot />
				</ProtectedRoute>
			),
			children: [
				{
					path: "/logout",
					...lazyLoadRoute(() => import("./routes/auth/logout")),
				},
				{
					path: "/home",
					...lazyLoadRoute(() => import("./routes/app")),
				},
				{
					path: "/account/settings",
					...lazyLoadRoute(() => import("./routes/app/account/settings")),
				},
				{
					path: "/editor/:formId",
					...lazyLoadRoute(() => import("./routes/app/editor")),
				},
			],
		},
	]);
