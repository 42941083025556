"use client";

import { useEffect } from "react";
import { configureAuth } from "react-query-auth";
import { z } from "zod";
import { handleApiError } from "./api-client";
import { useNavigate } from "react-router-dom";
import pathname from "node:path";
import React from "react";
import { PageLoad } from "@/components/ui/spinner/page-load";
import {
	getUserMe,
	postUserLogout,
	postUserLogin,
	postUserRegister,
} from "@/api-types/services.gen";
import { PostUserLoginData, PostUserRegisterData, User } from "@/api-types";
import * as Sentry from "@sentry/react";

const getUser = async (): Promise<User | null> => {
	try {
		const response = await getUserMe();
		if (response.data?.success && response.data) {
			return response.data.data;
		}
		return null;
	} catch (error) {
		Sentry.captureException(error);
		console.error("Error fetching user data:", error);
		return null;
	}
};

const logout = async (): Promise<void> => {
	await postUserLogout();
};

export const loginInputSchema = z.object({
	email: z.string().min(1, "Required").email("Invalid email"),
	password: z.string().min(5, "Required"),
});

export type LoginInput = z.infer<typeof loginInputSchema>;

const login = async (data: PostUserLoginData) => {
	const response = await postUserLogin(data);
	handleApiError(response);
	return response.data?.data;
};

async function register(data: PostUserRegisterData) {
	const response = await postUserRegister(data);
	handleApiError(response);
	return response.data?.data;
}

const authConfig = {
	userFn: getUser,
	loginFn: async (data: PostUserLoginData) => {
		const user = await login(data);
		return user;
	},
	registerFn: async (data: any) => {
		const user = await register(data);
		return user;
	},
	logoutFn: logout,
};

export const { useUser, useLogin, useLogout, useRegister, AuthLoader } =
	configureAuth(authConfig);
