import { Spinner } from "./spinner";

type PageLoadProps = {
	spinner?: boolean;
};
export const PageLoad = ({ spinner = false }: PageLoadProps) => {
	// Spinner turned off to prevent flickering
	return (
		<div className="flex size-full items-center justify-center my-5">
			{spinner && <Spinner size="lg" />}
		</div>
	);
};
